import app, { firestore } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import firebase from 'firebase';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
	auth: app.auth.Auth;
	db: app.firestore.Firestore;
	storage: app.storage.Storage;
	functions: app.functions.Functions;
	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.db = app.firestore();
		this.storage = app.storage();
		this.functions = app.app().functions('asia-northeast1');
	}

	enums = () => this.db.collection('enums');
	batch = () => this.db.batch();

	// Activity API's
	fetchCustomerSummaryData = () => this.db.collection('fetchCustomerSummaryData');
	fetchCustomerDetailData = () => this.db.collection('fetchCustomerDetailData');

	// Customer related APIs for Firebase
	customers = () => this.db.collection('customers');
	customerLocations = () => this.db.collection('customerLocations');

	// Contractor related APIs for Firebase
	contractors = () => this.db.collection('contractors');
	customerLocationContractors = () => this.db.collection('customerLocationContractors')
	oneTimePasscodes = () => this.db.collection('oneTimePasscodes');
	requests = () => this.db.collection('requests');
	requestInvoicing = () => this.db.collection('requestInvoicing');

	// Leads API's
	leads = () => this.db.collection('leads');
	leadsHistory = (id: string) => this.db.collection('leads').doc(id).collection('notes');

	timestamp = () => firebase.firestore.FieldValue.serverTimestamp()

	isAdmin = async() => {
		try {
			if(this.auth.currentUser){
				const userToken = await this.auth.currentUser && this.auth.currentUser.getIdTokenResult();
				return userToken.then((idTokenResult) => {
					return Promise.resolve(idTokenResult.claims.hasAdminAccess);
				})
			}
		} catch (error) {
			return Promise.reject(error);

		}
	}

	// Firebase API
	doSignInWithEmailAndPassword = async(email: string, password: string) => {

		try {
			await this.auth.setPersistence('session');
			return await this.auth.signInWithEmailAndPassword(email, password)
		} catch (error) {
			return Promise.reject(error);
		}
	};

	doSendPasswordResetEmail = (email: string) => {
		return this.auth.sendPasswordResetEmail(email);
	}

	doSignOut = () => {
		return this.auth.signOut();
	}
}

export default Firebase;
