import React from 'react';
import { withFirebase } from 'components/Firebase';

export const AuthContext = React.createContext<any | null>(null);

export const AuthProvider = withFirebase(({ children, ...props }) => {
	const [authUser, setAuthUser] = React.useState(null);
	const [ isAdmin, setIsAdmin ] = React.useState<boolean>(false)
	// const [ claims, setClaims ] = React.useState();

	React.useEffect(() => {
		props.firebase.auth.onAuthStateChanged(setAuthUser);
		if (authUser) {
			props.firebase.isAdmin()
				.then((result) => {
					setIsAdmin(Boolean(result))
				}).catch((err) => {
					console.log('ERROR', err)
				});
		}

	}, [ setAuthUser, authUser, setIsAdmin ]);

	return (
		<AuthContext.Provider value={{ authUser, isAdmin }}>
			{children}
		</AuthContext.Provider>
	)
});
