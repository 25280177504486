
import React from 'react';
import { LeadsContext } from './LeadsContext';
import { TextInput } from '@carbon/ibm-security';
import { ConvertNanoToDate } from 'utils/common';
import { CustomTextInput, IInputTextFormatProps } from 'components/CustomTextInput';
import { ICellObject } from 'utils/table';
import { ILead, ISetScheduleFollowUp } from './LeadsInterface';


const defaultScheduleFollowUp: ISetScheduleFollowUp = {
	amPm: 'AM',
	date: '',
	time: '',
	message: '',
	followUp: false
}


const getLeadById = (id: string, leads: ILead[]): ILead => {
	let editLead = {} as ILead;
	const leadsLength: number = leads.length;
	for(let i = 0; i < leadsLength; i++ ){
		if(leads[i].id === id){
			editLead = leads[i];
			break;
		}
	}
	return editLead;
}

const LeadsContactInformation: React.FunctionComponent = () => {
	const { state } = React.useContext(LeadsContext);

	const contactPhoneNumberProps: IInputTextFormatProps = {
		labelText: 'Contact phone number',
		id: `leads--contact-phone-number`,
		format: '#### ### ###',
		name: 'contactPhoneNumber',
		value: state.editLead.contactPhoneNumber,
		disabled: true
	}

	return (
		<React.Fragment>
			<div className={`sc--details-title title`}>Contact information</div>
					<TextInput
						{...{
							labelText: 'Company name',
							id: `leads--company-name`,
							name: 'companyName',
							value: state.editLead.company,
							disabled: true,
							size: 'xl'
						}}
					/>
					<TextInput
						{...{
							labelText: 'Contact name',
							id: `leads-contact-name`,
							name: 'contactName',
							value: `${state.editLead.firstName} ${state.editLead.lastName}`,
							disabled: true,
							size: 'xl'
						}}
					/>
					<TextInput
						{...{
							labelText: 'Contact email',
							id: `leads-email-name`,
							name: 'contactEmail',
							value: state.editLead.email,
							disabled: true,
							size: 'xl'
						}}
					/>
					<CustomTextInput {...contactPhoneNumberProps} />
					<TextInput
						{...{
							labelText: 'Contact date',
							id: `leads-date-number`,
							name: 'contactDate',
							value: ConvertNanoToDate(state.editLead.received as ICellObject),
							disabled: true,
							size: 'xl'
						}}
					/>
		</React.Fragment>
	)
};

export {
	getLeadById,
	defaultScheduleFollowUp,
	LeadsContactInformation
}
