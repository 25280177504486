/* eslint-disable no-useless-escape */
import React from 'react';
import Loading from '@carbon/ibm-security/lib/components/Loading';
import { CreateFlowContainer } from '../../components/CreateFlow';
import { Details, Locations, Manage } from './Steps';
import { IContractor, EFlowLabels } from './interface';
import { RegexFormat } from 'utils/common';
export interface IContractorAddProps {
	contractorDetails: IContractor;
	createNewContractor: () => void;
	handleCreateFlowView: (hide: boolean, mode: string) => void;
	loaderState: boolean;
}

export interface IContractorAddState {
	currentStep: number;
	isServiceEmailInvalid: boolean;
	contractorServiceEmail: string;
	isOpen: boolean;
}

const addProgressStepLabels = Object.values(EFlowLabels);

export default class ContractorAdd extends React.Component<IContractorAddProps, IContractorAddState> {
	constructor(props: IContractorAddProps) {
		super(props);

		this.state = {
			currentStep: 0,
			isOpen: false,
			isServiceEmailInvalid: false,
			contractorServiceEmail: ''
		};
	}

	handleServiceEmailInvalid = (isValid: boolean) => {
		this.setState({
			isServiceEmailInvalid: isValid
		})
	}

	handleProgressStep = (step: number) => {
		let nextStep: number = step;

		if (step === this.state.currentStep) {
			nextStep = step + 1;
			this.setState({
				currentStep: nextStep
			});
		} else {
			this.setState({
				currentStep: step
			});
		}
	};

	shouldEnableSave = (): boolean => {
		const contractorDetails = this.props.contractorDetails;
		const contractorAddress = contractorDetails.address;
		let disabled: boolean = true;

		switch(addProgressStepLabels[this.state.currentStep]) {
			case EFlowLabels.DETAILS:
				if (
					contractorDetails.companyName === '' ||
					contractorDetails.contactEmail === '' ||
					!contractorDetails.contactEmail.match(RegexFormat.email) ||
					contractorDetails.contactName === '' ||
					contractorDetails.abn === '' ||
					!contractorDetails.abn.match(RegexFormat.abn) ||
					contractorDetails.contactPhoneNumber === '' ||
					!contractorDetails.contactPhoneNumber.match(RegexFormat.phoneNumber) ||
					contractorAddress.addressLine1 === '' ||
					contractorAddress.city === '' ||
					contractorAddress.postcode === '' ||
					!contractorDetails.address.postcode.toString().match(RegexFormat.postCode) ||
					contractorAddress.state === ''
				) {
					disabled = true;
				} else {
					disabled = false
				}
				break;
			case EFlowLabels.LOCATIONS :
				return false;
			case EFlowLabels.MANAGE:
				if (
					contractorDetails.serviceContactEmail === '' ||
					!contractorDetails.serviceContactEmail.match(RegexFormat.email) ||
					contractorDetails.serviceContactName === '' ) {
					disabled = true;
				} else {
					disabled = false;
				}
		}
		return disabled;
	};

	handleServiceEmail = (value: string): void => {
		this.setState({
			contractorServiceEmail: value
		})
	}

	isLastStep = () =>
		this.state.currentStep === addProgressStepLabels.length - 1;

	public render() {

		const manageProps = {
			handleServiceEmailInvalid: this.handleServiceEmailInvalid,
			currentServiceContactEmail: this.state.contractorServiceEmail,
			handleServiceEmail: this.handleServiceEmail
		}

		return (
			<React.Fragment>
				<Loading active={this.props.loaderState} withOverlay={true} />
				<CreateFlowContainer
					{...{
						title: 'Add new contractor',
						createNewEntry: this.props.createNewContractor,
						currentStep: this.state.currentStep,
						handleProgressStep: this.handleProgressStep,
						handleCreateFlowView: this.props.handleCreateFlowView,
						isLastStep: this.isLastStep,
						mode: 'add',
						shouldEnableSave: this.shouldEnableSave,
						stepsType: addProgressStepLabels,
						renderMain: () => (
							<React.Fragment>
								{this.state.currentStep === 0 && <Details />}
								{this.state.currentStep === 1 && <Locations />}
								{this.state.currentStep === 2 && <Manage {...manageProps} />}
							</React.Fragment>
						)
					}}
				/>
			</React.Fragment>
		);
	}
}
