/* eslint-disable no-useless-escape */
import React from 'react';
import { LeadsContext } from '../LeadsContext';

import { TextArea } from '@carbon/ibm-security';
import { LeadsContactInformation } from '../LeadsUtils';
import { INotes } from '../LeadsInterface';
import moment from 'moment';


const History: React.FunctionComponent = () => {
	const { state } = React.useContext(LeadsContext);

	const renderHistory = React.useCallback((): JSX.Element => {
		const notesHistory: INotes[] = state.editLead.notes;
		if(notesHistory){
			return (
				<React.Fragment>
					{notesHistory.map((note: INotes, index: number): JSX.Element => {
						return (
							<div className={'lead-history-textarea-container'} key={`lead-textarea-${index}`}>
								<TextArea
									labelText={moment(note.date.seconds * 1000).format('LLLL')}
									disabled={true}
									value={note.note}
								/>
							</div>
						)
					})}
				</React.Fragment>
			)
		}
		return (
			<React.Fragment>
				{'No record of history to show'}
			</React.Fragment>
		)
	}, [state.editLead.notes]);

	return (
		<div className='sc--createflow-step details leads-details'>
			<div className='sc--createflow-column'>
				<LeadsContactInformation />
			</div>
			<div className='sc--createflow-column'>
				<div className={`sc--details-title title`}>History information</div>
					{renderHistory()}
			</div>
		</div>
	);
};

export { History };
