import * as React from 'react';
import ContractorMap from '../ContractorMap';

const STEP_PREFIX = 'sc--location';

const Locations: React.FunctionComponent<any> = () => {

	return (
		<div className='sc--createflow-step location'>
			<div className='sc--createflow-column'>
				<div>
					<div className={`${STEP_PREFIX}-title title`}>Service locations</div>
					<ContractorMap />
				</div>
			</div>
		</div>
	);
};

export { Locations };
