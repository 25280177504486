/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LeadsTable } from './LeadsTable';
import { LeadsContextProvider } from './LeadsContext';
import { DocumentTitle } from 'components/DocumentTitle';
import { Loader } from 'components/Loader';
import { EditLead } from './EditLead';
import { FirebaseContext } from 'components/Firebase';
import { EPageTitles } from 'utils/config';

interface ILoader {
	loading: boolean;
	loadingMessage?: string;
}

const defaultLoader: ILoader = {
	loading: false,
	loadingMessage: ''
}


const LeadsContainer: React.FunctionComponent = () => {

	const FBContext = React.useContext(FirebaseContext);
	const [loading, setIsLoading] = React.useState<ILoader>(defaultLoader);
	
	const updateLeadDetails = React.useCallback((leadId: string, message: string): PromiseLike<any> | undefined => {
		if(FBContext){
			setIsLoading({ loading: true });
			return FBContext
				.leadsHistory(leadId)
				.add({
					date: FBContext.timestamp(),
					note: message
				})
				.then(() => {
					return Promise.resolve();
				})
				.catch((error) => {
					return Promise.reject(error)
				})
				.finally(() => {
					setTimeout(() => {
						setIsLoading(defaultLoader)
					}, 300);
				})
		}
		return;
	}, [setIsLoading]);

	const loaderHandler = React.useCallback((isLoading: boolean, customMessage?: string) => {
		const load: ILoader = !isLoading ? defaultLoader : { loading: true, loadingMessage: customMessage || '' }
		setIsLoading(load);

	}, [setIsLoading]);

	return (
		<LeadsContextProvider>
			<DocumentTitle title={EPageTitles.Leads} />
			<React.Fragment>
				<div className={'sc--main-title'}>
					<h1>{EPageTitles.Leads}</h1>
				</div>
				{loading.loading && (<Loader message={loading.loadingMessage} /> )}
				<LeadsTable />
				<EditLead {...{
					updateLeadDetails,
					loaderHandler
				}}/>
			</React.Fragment>
		</LeadsContextProvider>
	)
};

export {
	LeadsContainer
}
