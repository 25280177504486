import React from 'react';
import { ICustomerLocationDetails, ISelectedCustomer } from 'modules/Customers/interface';
import ComposedModal, {
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'carbon-components-react/lib/components/ComposedModal';
import Button from '@carbon/ibm-security/lib/components/Button';
import Checkbox from '@carbon/ibm-security/lib/components/Checkbox';
import ContractorsContext from './ContractorsContext';
import isEqual from 'lodash/isEqual';
import { EContractorServices, ICustomerLocationContractors, ICustomerLocationServices } from './interface';
import { InlineNotification } from 'carbon-components-react';

export interface IContractorMapModal {
	closeMarkerModal: () => void;
	companyName: string;
	currentCustomerLocationContractor: () => ICustomerLocationContractors;
	kind: string;
	details: ICustomerLocationDetails[];
	open: boolean;
}

const initializeServices = (currentCustomerLocationContractor): ICustomerLocationServices[] | undefined => {
	const contractorServices: ICustomerLocationServices[] = currentCustomerLocationContractor();
	if (contractorServices === undefined) {
		return [];
	}
	return contractorServices;
}

const ContractorMapModal: React.FunctionComponent<IContractorMapModal> = (props) => {

	const contractorCtx = React.useContext(ContractorsContext);
	const [currentSelectedCustomer] = React.useState<ICustomerLocationDetails>(props.details[0]);
	const [services, setService] = React.useState(initializeServices(props.currentCustomerLocationContractor));
	const [multipleCustomersAtSingleLocation, setMultipleCustomersAtSingleLocation ] = React.useState(false);
	const [primaryButtonDisabled, setPrimaryButtonDisabled] = React.useState<boolean>(true);
	const initialServices = React.useRef(services);

	React.useEffect(() => {
		if(props.details.length > 1) {
			setMultipleCustomersAtSingleLocation(true);
		}
	}, []);

	React.useEffect(() => {
		const isDisabled: boolean = isEqual(services, initialServices.current);
		setPrimaryButtonDisabled(isDisabled)
	}, [services, initialServices]);

	const handleCheckboxValue = React.useCallback((checked: boolean, id: string, event: React.ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = target.value as unknown as ICustomerLocationServices;

		if (checked) {
			setService((oldArray) => [...oldArray, value])
		} else {
			setService(servicesArray => servicesArray && servicesArray.filter((service) => service !== value))
		}

	}, [setService]);

	const handleCancel = React.useCallback((removeContractorFromLocation?: boolean): void => {
		contractorCtx.handleCustomerLocationContractor(undefined, currentSelectedCustomer.customerLocationId, removeContractorFromLocation);
		props.closeMarkerModal();
	}, [currentSelectedCustomer])

	const handleLocationServices = React.useCallback(() => {
		const filterLocationIds = () => {
			return props.details.map((customerLocation: ICustomerLocationDetails): string => {
				return (
					customerLocation.customerLocationId as string
				)
			})
		}

		if (services && services.length === 0) {
			handleCancel(true);
		} else {
			const customerLocationId: string[] = filterLocationIds();
			const customerLocationServices: ICustomerLocationContractors = {
				contractorServices: services,
				customerLocationId: customerLocationId,
				customerId: currentSelectedCustomer.customer && currentSelectedCustomer.customer.id
			};
			contractorCtx.handleCustomerLocationContractor(customerLocationServices)
			props.closeMarkerModal();
		}
	}, [services, currentSelectedCustomer, multipleCustomersAtSingleLocation, props.details, contractorCtx, props.closeMarkerModal]);

	const isDefaultChecked = (currentValue): boolean | undefined => {
		if(services){
			return services.includes(currentValue);
		}
		return;
	};



	React.useEffect(() => {
		const createFlowElement = document.getElementsByClassName('sc--createflow')
		createFlowElement[0].classList.add('map-modal-active');

		return () => {
			createFlowElement[0].classList.remove('map-modal-active');
		}
	}, []);

	return (
		<ComposedModal
			className={'sc--composed-modal'}
			open={props.open}
			onClose={props.closeMarkerModal}
		>
			<ModalHeader
				{...{
					buttonOnClick: props.closeMarkerModal,
					iconDescription: 'Close',
					title: `${props.companyName} ${currentSelectedCustomer.address.city}`
				}}
			/>
			<ModalBody>
				<div>
					{ !multipleCustomersAtSingleLocation ? (
						<p>Reference: {currentSelectedCustomer.reference}</p>
					) : (
						<InlineNotification
							kind={'info'}
							lowContrast
							title={'Location has multiple customers'}
							subtitle={'Contractor will be assigned to all customers at this location'}
						/>
					)}
					<p className='sc--composed-modal--body'>
						What services are provided to this customer?
					</p>

					<div className='sc--services-checkbox-wrapper'>
						{contractorCtx.getContractorServiceTypes.map(
							(value: string, index: number) => {
								return (
									<Checkbox
										className='sc--services-checkbox'
										id={`sc-service-checkbox-${index}`}
										defaultChecked={isDefaultChecked(value)}
										indeterminate={false}
										key={`${value}-service-checkbox`}
										labelText={value}
										value={value}
										onChange={handleCheckboxValue}
									/>
								)
							}
						)}
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					{...{
						kind: 'secondary',
						onClick: () => props.closeMarkerModal()
					}}
				>
					{'Cancel'}
				</Button>
				<Button
					{...{
						disabled: primaryButtonDisabled,
						kind: 'primary',
						onClick: handleLocationServices
					}}
				>
					{'OK'}
				</Button>
			</ModalFooter>
		</ComposedModal>
	);
};

export { ContractorMapModal };
