import { defaultScheduleFollowUp } from './LeadsUtils';

enum ELeadsReducer {
  CurrentStep = 'CurrentStep',
  Leads = 'Leads',
  LeadHistory = 'LeadHistory',
  EditLead = 'EditLead',
  ResetLead = 'ResetLead',
  SetScheduleFollowUp = 'SetScheduleFollowUp',
  SetScheduleFollowUpValidate = 'SetScheduleFollowUpValidate',
  LeadViewType = 'LeadViewType',
  ConvertLead = 'ConvertLead'
}

const LeadsReducer = (prevState, action) => {
  switch (action.type) {
    case ELeadsReducer.CurrentStep:
      return {
			 ...prevState,
        currentStep: action.value
      };
    case ELeadsReducer.Leads:
      return {
        ...prevState,
        leads: action.value
      };
    case ELeadsReducer.EditLead:
      return {
        ...prevState,
        editLead: {
          ...prevState.editLead,
          ...action.value.lead
        },
        leadsView: action.value.leadsView
      };
    case ELeadsReducer.LeadHistory:
      return {
        ...prevState,
        editLead: {
          ...prevState.editLead,
          notes: action.value
        }
      };
    case ELeadsReducer.SetScheduleFollowUp:
      return {
        ...prevState,
        scheduleFollowUp: {
          ...prevState.scheduleFollowUp,
          ...action.value
        }
      }
    case ELeadsReducer.ResetLead:
      return {
        ...prevState,
        scheduleFollowUp: defaultScheduleFollowUp,
        leadsView: undefined
      }
    case ELeadsReducer.SetScheduleFollowUpValidate:
      return {
        ...prevState,
        scheduleFollowUpErrors: {
          ...prevState.scheduleFollowUpErrors,
          ...action.value
        }
      }
    case ELeadsReducer.ConvertLead:
      return {
        ...prevState,
        convertLead: action.value
      }
    default:
     return prevState;
  }
};

export {
  ELeadsReducer,
	LeadsReducer
}
