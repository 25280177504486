import { Dropdown, TextInput } from '@carbon/ibm-security';
import React from 'react';

import { getStates, stateMapper } from 'utils/common';
import ContractorsContext from '../ContractorsContext';
import { CustomTextInput, IInputTextFormatProps } from 'components/CustomTextInput';

const CONTRACTOR_ADD = 'contractor-add';
const STEP_PREFIX = 'sc--details';

const Details: React.FunctionComponent<any> = () => {
	const contractorCtx = React.useContext(ContractorsContext);

	const handleSelectedItem = (stateKey: string, { selectedItem }) => {
		contractorCtx.handleAddressPostCode(stateKey, selectedItem.id);
	};

	const handleDropdownInitialSelectedItem = (dropDownArray: { id: string; text: string }[], initialSelectedValue: string) => {
		const initialSelecteditem = dropDownArray.find((obj) => {
			return obj.text === initialSelectedValue;
		});
		return initialSelecteditem;
	};

	const abnProps: IInputTextFormatProps = {
		placeholder: 'Contractors 11 digit ABN number',
		labelText: 'Australian Business Number (ABN) *',
		format: '## ### ### ###',
		name: 'abn',
		value: contractorCtx.details.abn,
		onChange: contractorCtx.handleContractorInputChange,
		required: true
	}

	const contactPhoneNumberProps: IInputTextFormatProps = {
		labelText: 'Contact phone number *',
		id: `${CONTRACTOR_ADD}--contact-phone-number`,
		format: '#### ### ###',
		name: 'contactPhoneNumber',
		onChange: contractorCtx.handleContractorInputChange,
		value: contractorCtx.details.contactPhoneNumber,
		required: true
	}

	return (
		<div className='sc--createflow-step details'>
			<div className='sc--createflow-column'>
				<div className={`${STEP_PREFIX}-title title`}>{'Contact information'}</div>
				<TextInput
					{...{
						labelText: 'Company name *',
						id: `${CONTRACTOR_ADD}--company-name`,
						name: 'companyName',
						onChange: contractorCtx.handleContractorInputChange,
						value: contractorCtx.details.companyName,
						invalid: contractorCtx.details.companyName === '' && contractorCtx.mode === 'edit',
						invalidText: 'A company name is required',
						required: true
					}}
				/>
				<TextInput
					{...{
						labelText: 'Contact name *',
						id: `${CONTRACTOR_ADD}--contact-name`,
						name: 'contactName',
						onChange: contractorCtx.handleContractorInputChange,
						value: contractorCtx.details.contactName,
						required: true
					}}
				/>
				<CustomTextInput {...contactPhoneNumberProps} />
				<TextInput
					{...{
						labelText: 'Contact email address *',
						id: `${CONTRACTOR_ADD}--contact-email`,
						name: 'contactEmail',
						onChange: contractorCtx.handleContractorInputChange,
						value: contractorCtx.details.contactEmail,
						required: true
					}}
				/>
				<CustomTextInput {...abnProps} />
			</div>
			<div className='sc--createflow-column'>
				<div className={`${STEP_PREFIX}-title title`}>{'Address information'}</div>
				<TextInput
					{...{
						labelText: 'Address line 1 *',
						id: `${CONTRACTOR_ADD}--address-line-one`,
						name: 'addressLine1',
						onChange: contractorCtx.handleContractorAddressInputChange,
						value: contractorCtx.details.address.addressLine1,
						required: false
					}}
				/>
				<TextInput
					{...{
						labelText: 'Address line 2 (optional)',
						id: `${CONTRACTOR_ADD}--address-line-two`,
						name: 'addressLine2',
						onChange: contractorCtx.handleContractorAddressInputChange,
						value: contractorCtx.details.address.addressLine2,
						required: false
					}}
				/>
				<TextInput
					{...{
						labelText: 'City *',
						id: `${CONTRACTOR_ADD}--address-city`,
						name: 'city',
						onChange: contractorCtx.handleContractorAddressInputChange,
						value: contractorCtx.details.address.city,
						required: false
					}}
				/>
				<div className='sc--details-postcode-state-wrapper'>
					<Dropdown
						id={`${CONTRACTOR_ADD}--address-state`}
						ariaLabel={'state drop down filter'}
						items={getStates}
						itemToString={(item) => (item ? item.text : '')}
						initialSelectedItem={handleDropdownInitialSelectedItem(
							getStates,
							stateMapper(contractorCtx.details.address.state)
						)}
						label='Select state'
						onChange={(item) => handleSelectedItem('state', item)}
						titleText='State *'
					/>
					<TextInput
						{...{
							labelText: 'Postcode *',
							id: `${CONTRACTOR_ADD}--address-postcode`,
							name: 'postcode',
							onChange: contractorCtx.handleContractorAddressInputChange,
							value: contractorCtx.details.address.postcode,
							required: false
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export { Details };
