import React from 'react';
import { TextInput, TextInputSkeleton } from '@carbon/ibm-security';
import CustomersContext from '../CustomersContext';
import { FirebaseContext } from 'components/Firebase';
import Warning16 from '@carbon/icons-react/es/warning/16';
import { isEqual } from 'lodash';
import { RegexFormat } from 'utils/common';
import { CustomTextInput, IInputTextFormatProps } from 'components/CustomTextInput';
import { EMode } from 'modules/Contractors/interface';



// eslint-disable-next-line no-useless-escape
const emailRegex = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
const STEP_PREFIX = 'sc--manage';

interface IManageProps {
	currentServiceContactEmail?: string;
	handleServiceEmailInvalid: (isValid: boolean) => void;
	serviceEmailInvalid: boolean;
}

const Manage: React.FunctionComponent<IManageProps> = (props) => {
	const customerCtx = React.useContext(CustomersContext);
	const firebaseCtx = React.useContext(FirebaseContext);
	const [isEmailInvalid, setIsEmailInvalid] = React.useState<boolean>(props.serviceEmailInvalid);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
	const [inputLoader, setInputLoader] = React.useState<boolean>(false);
	const [phoneNumberValid, setValidPhonenumber] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (isEmailInvalid) {
			setEmailErrorMessage('The email address is already in use by another account.');
		}
		if (customerCtx.details.serviceContactPhone === '' && customerCtx.mode === EMode.EDIT) {
			setValidPhonenumber(true)
		}
	}, []);

	const validateEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const email = event.target.value;

		if (emailRegex.test(email)) {
			if (firebaseCtx && !isEqual(email, props.currentServiceContactEmail)) {
				setInputLoader(true);
				firebaseCtx.auth.fetchSignInMethodsForEmail(event.target.value)
					.then((response) => {
						if (response.length) {
							props.handleServiceEmailInvalid(true)
							setIsEmailInvalid(true)
							setEmailErrorMessage('The email address is already in use by another account.');
						} else {
							props.handleServiceEmailInvalid(false)
						}
					}).catch((err) => {
						console.log(err)
					})
					.finally(() => {
						setInputLoader(false)
					});
			} else {
				props.handleServiceEmailInvalid(false)
			}
		} else {
			props.handleServiceEmailInvalid(true)
			setIsEmailInvalid(true)
			setEmailErrorMessage('A valid email address is required');
		}

	}, [setIsEmailInvalid, setEmailErrorMessage, setInputLoader]);


	const handleEmailInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailErrorMessage('');
		setIsEmailInvalid(false)
		customerCtx.handleCustomerInputChange(event);
	}, [setEmailErrorMessage, setIsEmailInvalid]);

	const validatePhoneNumber = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const numberRegex = (RegexFormat.phoneNumber);
		setValidPhonenumber(!numberRegex.test(event.target.value))
	}, [setValidPhonenumber]);

	const contactPhoneNumberProps: IInputTextFormatProps = {
		labelText: 'Contact phone number *',
		id: `${STEP_PREFIX}--service-user-contact-phone-number`,
		format: '#### ### ###',
		name: 'serviceContactPhone',
		onChange: customerCtx.handleCustomerInputChange,
		onBlur: validatePhoneNumber,
		value: customerCtx.details.serviceContactPhone,
		required: true,
		invalid: phoneNumberValid,
		invalidText: 'A valid phone number is required'
	}

	return (
		<div className='sc--createflow-step manage'>
			<div className='sc--createflow-column'>
				<div>
					<div className={`${STEP_PREFIX}-title title`}>{'User setup'}</div>
					<p className={'sc--body'}>
						{`Provide the name and email address who will be able to create additional users to manage the account for ${customerCtx
							.details.companyName || 'the customer'}`}
					</p>
				</div>
				<TextInput
					{...{
						labelText: 'Contact name',
						id: `customer-${customerCtx.mode}--service-contact-name`,
						name: 'serviceContactName',
						onChange: customerCtx.handleCustomerInputChange,
						invalid: customerCtx.details.serviceContactName === '' && customerCtx.mode === 'edit',
						invalidText: 'A contact name is required',
						value: customerCtx.details.serviceContactName,
						required: true
					}}
				/>
				<CustomTextInput {...contactPhoneNumberProps} />
				{inputLoader ? (
					<TextInputSkeleton />
				) : (
						<TextInput
							{...{
								labelText: 'Contact email',
								id: `customer-${customerCtx.mode}--service-contact-email`,
								name: 'serviceContactEmail',
								onChange: handleEmailInput,
								invalid: isEmailInvalid,
								invalidText: emailErrorMessage,
								onBlur: validateEmail,
								value: customerCtx.details.serviceContactEmail,
								required: true
							}}
						/>
					)}
				<div className='bx--row'>
					<p className='sc--body description bx--col'>
						A temporary password will be sent to the provided name and email details above.
					</p>
				</div>
				{customerCtx.mode === 'edit' && (
					<div className='bx--row'>
						<p className='sc--body sc--warning bx--col'>
							<Warning16 />
							Changing the contact name and email address will disable the
							existing user from managing the account.
						</p>
					</div>
				)}
			</div>
			<div className='sc--createflow-column'>
				<div>
					<div className={`${STEP_PREFIX}-title title`}>Contractor payments</div>
					<p className={'sc--body'}>
						Provide an email address that will receive and handle invoices from contractors.
					</p>
				</div>
				<TextInput
					{...{
						labelText: 'Payment email *',
						id: `customer-${customerCtx.mode}--payment-email`,
						name: 'paymentEmail',
						onChange: customerCtx.handleCustomerInputChange,
						value: customerCtx.details.paymentEmail,
						required: true
					}}
				/>
			</div>
		</div>
	);
};

export { Manage };
