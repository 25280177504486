import { ICustomerLocationDetails, ICustomerAddress, IGoogleMarker } from 'modules/Customers/interface';

const mapConsts = {
	BASE_GOOGLE_GEOCODE_URL: 'https://maps.googleapis.com/maps/api/geocode/json',
	DEFAULT_COUNTRY_AUSTRALIA: 'Australia',
	DEFAULT_LAT_LNG: {
		lat: -28.016666,
		lng: 153.399994
	},
	DEFAULT_STATE: 'OLD'
};

/**
 * Used for contractors dropdown to load a specific customer information
 * once we have received the payload from firebase
 */
const setMarkerDetails = (customerArray: ICustomerLocationDetails[] = []): IGoogleMarker[] => {
	const tempObj: IGoogleMarker[] = [];
	customerArray.map((locationObject) => {
		const locationEntry: IGoogleMarker = {
			name: `${locationObject.companyName} ${locationObject.address.city}`,
			location: {
				lat: locationObject.geometry && locationObject.geometry.location.lat,
				lng: locationObject.geometry && locationObject.geometry.location.lng
			},
			reference: locationObject.reference
		};
		tempObj.push(locationEntry);
	});
	return tempObj;
};

/**
 * @function getCurrentUserLocation
 */

const getCurrentUserLocation = (contextAddress: ICustomerAddress): string => {
	const addressObj = contextAddress;
	const address: string[] = [];
	let urlString: string = '';

	if (addressObj.city) {
		Object.keys(addressObj).forEach((prop) => {
			if (addressObj[prop]) {
				address.push(addressObj[prop]);
			}
			return address;
		});
	}
	return (urlString = encodeURIComponent(address.join(' ')));
};

/**
 * @function getLocationType
 * @param addressComponent response payload from google api geo coding. eg: results[0].address_component
 * @param type types[] is an array indicating the type of the address component. Examples type 'administrative_area_level_1'.
 */
const getLocationType = (addressComponent, type: string): string => {
	let tempVar: string = '';

	for (const componentObj in addressComponent) {
		const component = addressComponent[componentObj];
		for (const typeIndex in component.types) {
			if (component.types[typeIndex] === type) {
				tempVar = component.short_name;
			}
		}
	}

	return tempVar;
};

export { getCurrentUserLocation, getLocationType, setMarkerDetails, mapConsts };
