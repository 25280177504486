/* eslint-disable no-useless-escape */
import React from 'react';
import { LeadsContext } from '../LeadsContext';
import { FirebaseContext } from 'components/Firebase';

import { Checkbox, DatePicker, DatePickerInput, InlineNotification, SelectItem, TextArea, TimePicker, TimePickerSelect } from '@carbon/ibm-security';
import { ELeadsReducer } from '../LeadsReducer';
import moment from 'moment';
import { LeadsContactInformation } from '../LeadsUtils';

const Details: React.FunctionComponent = () => {
	const { state, dispatch } = React.useContext(LeadsContext);
	const firebaseCtx = React.useContext(FirebaseContext);

	const handleScheduleCheckBox = React.useCallback((value: boolean, id: string, event: React.ChangeEvent<HTMLInputElement>): void => {
		dispatch({
			type: ELeadsReducer.SetScheduleFollowUp,
			value: { followUp: value }
		});
	}, [dispatch]);

	const handleTimePicker = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const time: string = event.target.value;
		const regex = new RegExp('^(0?[1-9]|1[0-2]):[0-5][0-9]$');

		const isValid: boolean = regex.test(time);
		dispatch({
			type: ELeadsReducer.SetScheduleFollowUpValidate,
			value: { timePickerInputError: !isValid }
		});
		dispatch({
			type: ELeadsReducer.SetScheduleFollowUp,
			value: { time: event.target.value }
		});
	}, [dispatch])

	const handleAmPm = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({
			type: ELeadsReducer.SetScheduleFollowUp,
			value: { amPm: event.target.value }
		});
	}, [dispatch])

	const handleDatePicker = React.useCallback((date: any) => {
		let setDateTime: string = '';
		const datePickerError: boolean = date.length ? false : true;

		if (date.length) {
			setDateTime = moment(date[0]).format('DD/MM/YYYY');
		}

		dispatch({
			type: ELeadsReducer.SetScheduleFollowUp,
			value: { date: setDateTime }
		});
		dispatch({
			type: ELeadsReducer.SetScheduleFollowUpValidate,
			value: { datePickerInputError: datePickerError }
		});

	}, [dispatch]);

	const handleMessage = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {

		dispatch({
			type: ELeadsReducer.SetScheduleFollowUp,
			value: { message: event.target.value }
		});
	}, [dispatch]);



	return (
		<div className='sc--createflow-step details leads-details'>
			<div className='sc--createflow-column'>
				<LeadsContactInformation />
			</div>
			<div className='sc--createflow-column'>
				<div className={`sc--details-title title`}>Create calendar event</div>
				<TextArea
					{...{
						labelText: 'Note',
						id: `leads-date-number`,
						name: 'contactNotes',
						placeholder: 'Enter details',
						onChange: handleMessage,
						value: state.scheduleFollowUp.message
					}}
				/>
					<Checkbox
					{...{
						id: 'leads-check-box',
						labelText: 'Schedule follow-up (optional)',
						onChange: handleScheduleCheckBox,
						checked: state.scheduleFollowUp.followUp
					}}
				/>
				<div className='leads-details-date-time-wrapper'>
					<DatePicker
						dateFormat='d/m/yy'
						datePickerType='single'
						id='date-picker'
						light={false}
						locale='en'
						minDate='today'
						onChange={handleDatePicker}
						value={state.scheduleFollowUp.date}
					>
						<DatePickerInput
							className='some-class'
							iconDescription='Icon description'
							id='date-picker-input-id'
							invalid={state.scheduleFollowUpErrors.datePickerInputError}
							invalidText='A valid value is required'
							labelText='Date'
							pattern='\\d{1,2}\\/\\d{1,2}\\/\\d{4}'
							placeholder='dd/mm/yyyy'
							type='text'
							autoComplete='off'
							disabled={!state.scheduleFollowUp.followUp}
						/>
					</DatePicker>
					<TimePicker
						disabled={!state.scheduleFollowUp.followUp}
						id='schedule-followup-time-picker'
						invalid={state.scheduleFollowUpErrors.timePickerInputError}
						invalidText='A valid value is required'
						labelText='Time (12hr)'
						light={false}
						maxLength={5}
						onBlur={handleTimePicker}
						value={state.scheduleFollowUp.time}
						placeholder='hh:mm'
						type='text'
						autoComplete='off'
					>
						<TimePickerSelect
							disabled={!state.scheduleFollowUp.followUp}
							hideLabel
							iconDescription='open list of options'
							id='time-picker-select-1'
							inline
							labelText='Please select'
							defaultValue={'AM'}
							onChange={handleAmPm}
						>
							<SelectItem
								disabled={false}
								hidden={false}
								text='AM'
								value='AM'
							/>
							<SelectItem
								disabled={false}
								hidden={false}
								text='PM'
								value='PM'
							/>
						</TimePickerSelect>
					</TimePicker>
				</div>

				<InlineNotification
					className='leads-details-follow-up-notification'
					hideCloseButton
					iconDescription='Info'
					kind='info'
					notificationType='inline'
					role='alert'
					subtitle='Schedule follow up creates a downloadable .ics file with the leads contact details, event date and follow-up note.'
					title=''
				/>
			</div>
		</div>
	);
};

export { Details };
