enum EPageTitles {
	Activity = 'Activity',
	Contractors = 'Contractors',
	Customers = 'Customers',
	Dashboard = 'Dashboard',
	Leads = 'Leads'
}

export {
	EPageTitles
}
