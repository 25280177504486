import React from 'react';
import Warning20 from '@carbon/icons-react/es/warning/20';
import Checkmark20 from '@carbon/icons-react/es/checkmark--outline/20';
import Link from '@carbon/ibm-security/lib/components/Link';
import { InlineLoading, InlineLoadingStatus, SkeletonText } from 'carbon-components-react';

export interface IOneTimePasscodeProps {
	name?: string;
	resetOTPValidation?: () => void;
	collectionId: string;
	hasValidated: boolean;
	serviceType: string;
	handleRenewOtp: (customerLocationId: string) => boolean;
	isLoading?: boolean;
}

enum EInlineLoaderStatus {
	ACTIVE = 'active',
	ERROR = 'error',
	FINISHED = 'finished'
}

const OneTimePasscode: React.FunctionComponent<IOneTimePasscodeProps> = (props) => {

	const {
		collectionId,
		handleRenewOtp,
		hasValidated,
		name,
		serviceType
	} = props;

	const [inlineLoader, setInlineLoader] = React.useState<boolean>(false);
	const [otpRenewStatus, setOtpRenewStatus] = React.useState({
		status: EInlineLoaderStatus.ACTIVE,
		description: `Sending new one time passcode to ${name}`
	})

	const handleButtonClick = React.useCallback(async () => {
		setInlineLoader(true);
		const hasRenewedOtp: boolean = await handleRenewOtp(collectionId);
		if (hasRenewedOtp) {
			setOtpRenewStatus({ status: EInlineLoaderStatus.FINISHED, description: `Success, one time passcode sent to ${name}` })
			if(props.resetOTPValidation) {
				props.resetOTPValidation();
			}
		} else {
			setOtpRenewStatus({ status: EInlineLoaderStatus.ERROR, description: `Error, creating time passcode for ${name}` })
		}

	}, [setInlineLoader, collectionId, setOtpRenewStatus, props.resetOTPValidation])

	const handleSuccess = React.useCallback(() => {
		setInlineLoader(false);
		setOtpRenewStatus({ status: EInlineLoaderStatus.ACTIVE, description: `Sending new one time passcode to ${name}` })
	}, [setInlineLoader, setOtpRenewStatus]);

	if (props.isLoading) {
		return (
			<div className='bx--row'>
				<div className='bx--col'>
					<SkeletonText
						heading
						lineCount={2}
						className='sc--otp-container'
					/>
				</div>
			</div>
		)
	}

	return (
		<React.Fragment>
			{hasValidated ? (
				<div className='bx--row'>
					<div className='bx--col sc--otp-valid'>
						<Checkmark20 />
						<div>{`${serviceType} has successfully validated the one-time passcode with Simplyclik mobile app.`}</div>
					</div>
				</div>
			) : (
				<div className='bx--row'>
					<div className='sc--body sc--warning bx--col'>
						<Warning20 />
						<div>{`${serviceType} has not validated the one-time passcode with SimplyClik mobile app.`}</div>
					</div>
				</div>
			)}
			<div className='bx--row'>
				<div className='bx--col'>
					<div className='sc--otp-container'>
						{inlineLoader ? (
							<InlineLoading
								onSuccess={handleSuccess}
								successDelay={4000}
								status={otpRenewStatus.status as InlineLoadingStatus}
								className={`sc--otp-inline-loader ${otpRenewStatus.status}`}
								description={otpRenewStatus.description}
							/>
						) : (
							<Link
								className={`sc--send-otp-link`}
								onClick={handleButtonClick}>
								{'Resend one-time passcode'}
							</Link>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default OneTimePasscode;
