import React from 'react';
import Search from '@carbon/ibm-security/lib/components/Search';

const SearchBox: React.FunctionComponent<any> = (props) => {
	return (
		<Search
			className={'sc--customer-search-input'} //'some-class'
			closeButtonLabelText='Clear search input'
			id={props.id} //'sc--customer-search-input'
			labelText='Search'
			name='search-location'
			onChange={(event) => {
				props.handleSearchInput(event);
			}}
			placeholder='Search for a service location'
			type='text'
		/>
	);
};

export { SearchBox };
