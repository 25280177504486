import React from 'react';
import GoogleMapReact from 'google-map-react';
import CustomersContext from 'modules/Customers/CustomersContext';
import { MarkerModal } from './MarkerModal';
import {
	ICustomerLocationDetails,
	IGoogleMarker,
	ICustomerLocation
} from 'modules/Customers/interface';
import { withFirebase } from 'components/Firebase';
import { isEmpty, isEqual } from 'lodash';
import { SearchBox } from 'components/SearchBox';
import InlineLoading from '@carbon/ibm-security/lib/components/InlineLoading';
import { mapConsts, getCurrentUserLocation, getLocationType } from 'utils/map';
import { ToastNotification } from '@carbon/ibm-security/lib/components/Notification';

interface IMapsContainerState {
	customerLocationDetails: ICustomerLocationDetails[];
	deviceCenter: {
		lat: number | null;
		lng: number | null;
	};
	geoSet: boolean;
	markerCoords: IGoogleMarker[];
	queryString: string;
	selectedCustomer: ICustomerLocationDetails[];
	showNotification: boolean;
}

declare let google: any;

class Map extends React.Component<any, IMapsContainerState> {
	markersArray: any = [];
	searchedString: string = '';
	static defaultProps = {
		center: mapConsts.DEFAULT_LAT_LNG,
		zoom: 12
	};
	static contextType = CustomersContext;
	customerId: string = '';

	constructor(props) {
		super(props);
		this.state = {
			customerLocationDetails: [],
			geoSet: false,
			deviceCenter: {
				lat: null,
				lng: null
			},
			markerCoords: [],
			queryString: '',
			selectedCustomer: [],
			showNotification: false
		};
	}

	componentDidMount() {
		const searchNode = document.getElementById('sc--customer-search-input');
		searchNode && searchNode.addEventListener('keypress', this.handleKeyPress);
		this.customerId = this.context.details.id;
		this.handleUserLocation();
	}

	handleUserLocation = () => {
		const encodedUsersAddress: string = getCurrentUserLocation(this.context.details.address);

		if (encodedUsersAddress) {
			fetch(
				`${mapConsts.BASE_GOOGLE_GEOCODE_URL}?address=${encodedUsersAddress}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
			)
				.then(response => response.json())
				.then(data => {
					if (data.status === 'OK') {
						const dataObj = data.results[0];
						const locationState: string = getLocationType(dataObj.address_components, 'administrative_area_level_1');
						const { lat, lng } = dataObj.geometry.location;

						this.setState({
							deviceCenter: {
								lat,
								lng
							}
						}, () => this.getCustomerLocationsByState(locationState));
					}

					if (data.status === 'ZERO_RESULTS') {
						this.setState({ showNotification: true }, this.setDefaultLocation);
					}
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			this.setState({
				deviceCenter: {
					lat: this.props.center.lat,
					lng: this.props.center.lng
				},
				geoSet: true
			});
		}
	};

	getCustomerLocationsByState = (locationState: string) => {
		const customerLocationDetails = this.context
			.getCustomerLocations(this.customerId, locationState)
			.then((result) => {
				this.setState({
						customerLocationDetails: result,
						geoSet: true
					}, () => this.setMarkerDetails());
			})
			.catch(err => {
				console.log('ERROR: ', err, customerLocationDetails);
			});
	};

	setMarkerDetails = () => {
		const tempObj: IGoogleMarker[] = [];
		this.state.customerLocationDetails.map(locationObject => {
			const locationEntry: IGoogleMarker = {
				name: `${this.context.details.companyName} ${locationObject.address.city}`,
				location: {
					lat: locationObject.geometry && locationObject.geometry.location.lat,
					lng: locationObject.geometry && locationObject.geometry.location.lng
				},
				reference: locationObject.reference
			};
			tempObj.push(locationEntry);
		});
		this.setState({ markerCoords: tempObj });
	};

	handleApiLoaded = (map, maps) => {
		// var bounds = new google.maps.LatLngBounds();

		const setMapOnAll = () => {
			for (let i = 0; i < this.markersArray.length; i++) {
				this.markersArray[i].setMap(null);
			}
		};

		setMapOnAll();

		const checkSelectedCustomerLocations = () => {
			const customerLatLng = this.state.deviceCenter;
			const selectedCustomer = this.state.customerLocationDetails.filter((location) => {
				return isEqual(customerLatLng, location.geometry?.location)
			})
			this.setState({
				selectedCustomer: selectedCustomer
			})
		}

		this.state.customerLocationDetails.map((location: ICustomerLocationDetails) => {
			const geo = location.geometry && location.geometry.location;
			const title = location.companyName;
			const position = new google.maps.LatLng(geo && geo.lat, geo && geo.lng);

			const marker = new maps.Marker({
				position: position,
				map: map,
				title: title
			});

			this.markersArray.push(marker);
			// bounds.extend(position);

			marker.addListener('click', () => {
				this.setState({
					selectedCustomer: [],
					deviceCenter: {
						lat: marker.getPosition().lat(),
						lng: marker.getPosition().lng()
					}
				}, () => checkSelectedCustomerLocations());
			});
		});


		// map.fitBounds(bounds);

		// map.addListener('idle', () => {
		// 	this.getMarkers(map);
		// });
	};

	setDefaultLocation = () => {
		this.setState({
			deviceCenter: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			geoSet: true
		});
	};

	closeMarkerModal = () => {
		this.setState({ selectedCustomer: [] });
	};

	/**
	 * @function handleSearchInput to handle the search query for
	 * google maps places library.
	 */
	handleSearchInput = (event: any) => {
		this.setState({ queryString: event.target.value });
	};

	// Check user has clicked enter to submit custom query
	// to google maps api.
	handleKeyPress = (event: any) => {
		const customerName = this.context.details.companyName;
		const { queryString } = this.state;

		if (event.keyCode === 13) {
			this.searchedString = queryString;
			const urlString = encodeURIComponent(`${customerName} ${queryString}`);

			this.setState({
				geoSet: false,
				showNotification: false,
				selectedCustomer: []
			});

			fetch(`${mapConsts.BASE_GOOGLE_GEOCODE_URL}?address=${urlString}&components=country:AU&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
				.then(response => response.json())
				.then(data => {

					if (data.status === 'OK') {
						const dataObj = data.results[0];
						const { lat, lng } = dataObj.geometry.location;
						const locationState: string = getLocationType(
							dataObj.address_components,
							'administrative_area_level_1'
						);
						this.setState({
								deviceCenter: {
									lat,
									lng
								}
							}, () => this.getCustomerLocationsByState(locationState));
					}

					if (data.status === 'ZERO_RESULTS') {
						this.setState({
							showNotification: true
						}, this.setDefaultLocation );
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
	};

	render() {
		const { selectedCustomer } = this.state;
		const markerModalProps = {
			closeMarkerModal: this.closeMarkerModal,
			kind: 'success',
			details: selectedCustomer
		};

		return (
			<React.Fragment>
				<div className='bx--row service-locations-search-box'>
					<div className='bx--col-lg-8'>
						<SearchBox
							{...{
								id: 'sc--customer-search-input',
								handleSearchInput: this.handleSearchInput
							}}
						/>
					</div>
				</div>
				{this.state.showNotification && (
					<div className='bx--row'>
						<div className='bx--col-lg-8'>
							<ToastNotification
								title='0 results found'
								subtitle={`${this.searchedString} did not return any results`}
								kind='warning'
								style={{
									width: 'inherit',
									marginTop: '0'
								}}
								caption={null}
							/>
						</div>
					</div>
				)}
				<div className='bx--row'>
					<div className='bx--col-lg-8 sc--map-container'>
						{!this.state.geoSet ? (
							<div className='map-loader'>
								<InlineLoading description='Loading map data...' />
							</div>
						) : (
								<GoogleMapReact
									bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
									center={this.state.deviceCenter}
									defaultZoom={this.props.zoom}
									yesIWantToUseGoogleMapApiInternals
									onGoogleApiLoaded={({ map, maps }) =>
										this.handleApiLoaded(map, maps)
									}
								/>
							)}
					</div>
					{!isEmpty(selectedCustomer) && (
						<div className='bx--col-lg-8'>
							<MarkerModal {...markerModalProps} />
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}
}

const MapContainer = withFirebase(Map);
export default MapContainer;
