/* eslint-disable no-undef */
enum EFileStatus {
	EDIT = 'edit',
	COMPLETE = 'complete',
	UPLOADING = 'uploading'
}

interface IFileProps {
	fileStatus: string;
	fileName: string;
}

const fileStatus = ['edit', 'uploading', 'complete'];
const setFileStatus = (status: number) => {
	return fileStatus[status];
}

const getFileInstance = (event: React.ChangeEvent<HTMLInputElement>): File => {
	const files = event.target.files as FileList;
	return files[0];
};

const setEditState = (): string => {
	return EFileStatus.EDIT;
};

const formatBytes = (bytes, decimals: number = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export { IFileProps, getFileInstance, EFileStatus, setEditState, setFileStatus, formatBytes };
