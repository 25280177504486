/* eslint-disable no-useless-escape */
import { Dropdown, TextInput, FileUploaderButton, FileStatus, FileUploaderItem } from '@carbon/ibm-security';
import React from 'react';
import { getStates, stateMapper, RegexFormat } from 'utils/common';

import CustomersContext from '../CustomersContext';
import { IFileProps } from 'utils/files';
import { CustomTextInput, IInputTextFormatProps } from 'components/CustomTextInput';
import { EMode } from 'modules/Contractors/interface';

const CUSTOMER_ADD = 'customer-add';
const STEP_PREFIX = 'sc--details';

interface IDetailsProps {
	logo?: IFileProps;
}

const Details: React.FunctionComponent<IDetailsProps> = (props) => {
	const customerCtx = React.useContext(CustomersContext);

	const [emailValid, setEmailValidity] = React.useState<boolean>(false);
	const [postcodeValid, setPostCodeValidity] = React.useState<boolean>(false);
	const [phoneNumberValid, setValidPhonenumber] = React.useState<boolean>(false);

	const modeType: string = customerCtx.mode === EMode.EDIT ? 'editableCustomer' : 'newCustomerDetails';

	const handleSelectedItem = (stateKey: string, { selectedItem }) => {
		customerCtx.handleAddressPostCode(stateKey, selectedItem.id);
	};

	const handleDropdownInitialSelectedItem = (
		dropDownArray: { id: string; text: string }[],
		initialSelectedValue
	) => {
		const initialSelecteditem = dropDownArray.find((obj) => {
			return obj.text === initialSelectedValue;
		});
		return initialSelecteditem;
	};

	const validateEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const emailRegex = (RegexFormat.email);
		setEmailValidity(!emailRegex.test(event.target.value));
	}, [setEmailValidity]);

	const validatePostcode = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const numberRegex = (RegexFormat.postCode);
		setPostCodeValidity(!numberRegex.test(event.target.value))
	}, [setPostCodeValidity]);

	const validatePhoneNumber = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const numberRegex = (RegexFormat.phoneNumber);
		setValidPhonenumber(!numberRegex.test(event.target.value))
	}, [setValidPhonenumber]);


	const contactPhoneNumberProps: IInputTextFormatProps = {
		labelText: 'Contact phone number *',
		id: `${CUSTOMER_ADD}--contact-phone-number`,
		format: '#### ### ###',
		name: 'contactPhoneNumber',
		onChange: customerCtx.handleCustomerInputChange,
		onBlur: validatePhoneNumber,
		value: customerCtx.details.contactPhoneNumber,
		required: true,
		invalid: phoneNumberValid,
		invalidText: 'A valid phone number is required'
	}

	return (
		<div className='sc--createflow-step details'>
			<div className='sc--createflow-column'>
				<div className={`${STEP_PREFIX}-title title`}>Contact information</div>
				<TextInput
					{...{
						labelText: 'Company name *',
						id: `${CUSTOMER_ADD}--company-name`,
						name: 'companyName',
						onChange: customerCtx.handleCustomerInputChange,
						invalid: customerCtx.details.companyName === '' && customerCtx.mode === 'edit',
						invalidText: 'A company name is required',
						value: customerCtx.details.companyName,
						required: true
					}}
				/>
				<TextInput
					{...{
						labelText: 'Contact name *',
						id: `${CUSTOMER_ADD}--contact-name`,
						name: 'contactName',
						onChange: customerCtx.handleCustomerInputChange,
						invalid: customerCtx.details.contactName === '' && customerCtx.mode === 'edit',
						invalidText: 'A contact name is required',
						value: customerCtx.details.contactName,
						required: true
					}}
				/>

				<CustomTextInput {...contactPhoneNumberProps} />
				<TextInput
					{...{
						labelText: 'Contact email address *',
						id: `${CUSTOMER_ADD}--contact-email`,
						name: 'contactEmail',
						onChange: customerCtx.handleCustomerInputChange,
						onBlur: validateEmail,
						invalid: emailValid,
						invalidText: 'A valid email address is required',
						value: customerCtx.details.contactEmail,
						required: true,
						type: 'email'
					}}
				/>

				<div className='sc--createflow-fileupload'>
					<div>
						<strong className='bx--file--label'>Business logo *</strong>
						<p className='bx--label-description'>Only .jpg and .png files. 200kb max file size</p>
							<FileUploaderButton
								accept={['image/jpeg', 'image/png']}
								disableLabelChanges={true}
								labelText={'Add file'}
								multiple={false}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									customerCtx.handleFileUpload(event);
								}}
							/>
							{customerCtx.logoName && (
								<FileUploaderItem
								name={customerCtx.logoName}
								// status={customerCtx.fileUploadStatus}
								status={'complete'}
								onDelete={() => customerCtx.handleFileDelete(modeType)}
							/>
							)}
					</div>

						{customerCtx.details.logo && (
							<div className='sc--business-logo'>
								<img src={customerCtx.details.logo} alt='' />
							</div>
						)}
					</div>
				</div>
				<div className='sc--createflow-column'>
					<div className={`${STEP_PREFIX}-title title`}>Address information</div>
					<TextInput
						{...{
							labelText: 'Address line 1 *',
							id: `${CUSTOMER_ADD}--address-line-one`,
							name: 'addressLine1',
							onChange: customerCtx.handleCustomerAddressInputChange,
							invalid: customerCtx.details.address.addressLine1 === '' && customerCtx.mode === 'edit',
							invalidText: 'A address is required',
							value: customerCtx.details.address.addressLine1,
							required: false
						}}
					/>
					<TextInput
						{...{
							labelText: 'Address line 2 (optional)',
							id: `${CUSTOMER_ADD}--address-line-two`,
							name: 'addressLine2',
							onChange: customerCtx.handleCustomerAddressInputChange,
							value: customerCtx.details.address.addressLine2,
							required: false
						}}
					/>
					<TextInput
						{...{
							labelText: 'City *',
							id: `${CUSTOMER_ADD}--address-city`,
							name: 'city',
							onChange: customerCtx.handleCustomerAddressInputChange,
							invalid: customerCtx.details.address.city === '' && customerCtx.mode === 'edit',
							invalidText: 'City is a required field',
							value: customerCtx.details.address.city,
							required: false
						}}
					/>
					<div className='sc--details-postcode-state-wrapper'>
						<Dropdown
							id={`${CUSTOMER_ADD}--address-state`}
							ariaLabel={'state drop down filter'}
							items={getStates}
							itemToString={(item) => (item ? item.text : '')}
							initialSelectedItem={handleDropdownInitialSelectedItem(
								getStates,
								stateMapper(customerCtx.details.address.state)
							)}
							label='Select state'
							onChange={(item) => handleSelectedItem('state', item)}
							titleText='State *'
						/>
						<TextInput
							{...{
								labelText: 'Postcode *',
								id: `${CUSTOMER_ADD}--address-postcode`,
								name: 'postcode',
								onChange: customerCtx.handleCustomerAddressInputChange,
								onBlur: validatePostcode,
								invalid: postcodeValid,
								invalidText: 'Invalid postcode',
								value: customerCtx.details.address.postcode,
								required: false
							}}
						/>
					</div>
					{/* <div className='bx--form-item sc--details-country-wrapper'>
					<Dropdown
						id={`${CUSTOMER_ADD}--country`}
						ariaLabel={'country drop down filter'}
						items={getCountries}
						itemToString={(item) => (item ? item.text : '')}
						initialSelectedItem={handleDropdownInitialSelectedItem(
							getCountries,
							customerCtx.details.address.country
						)}
						label='Select Country'
						onChange={(item) => handleSelectedItem('country', item)}
						titleText='Country'
					/>
				</div> */}
				</div>
			</div>
			);
		};

export { Details };
