import { DataTable, TableContainer, TableToolbar, TableToolbarContent, TableToolbarSearch, Button, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, OverflowMenu, OverflowMenuItem, DataTableSkeleton } from '@carbon/ibm-security';
import React from 'react';
import { Add16, Edit16 } from '@carbon/icons-react';
import { ITableHeaderItem, IRow, ICell, getItemRangeText, getPageRangeText, PageSizes, ICellObject } from 'utils/table';
import { ETableHeaderMap, ICustomer } from 'modules/Customers/interface';
import { ELeadEditLabels, ELeadsEditSteps, ELeadsTableHeaderValues, ILead, INotes } from './LeadsInterface';
import { LeadsContext } from './LeadsContext';
import { FirebaseContext } from 'components/Firebase';
import { ConvertNanoToDate } from 'utils/common';
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';
import { ELeadsReducer } from './LeadsReducer';
import { getLeadById } from './LeadsUtils';

import ComposedModal, {
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'carbon-components-react/lib/components/ComposedModal';

import emptyState from '../../assets/empty-state.png';

const headers: ITableHeaderItem[] = [
	{
		header: ELeadsTableHeaderValues.Name,
		key: 'firstName'
	},
	{
		header: ELeadsTableHeaderValues.Received,
		key: 'received'
	},
	{
		header: ELeadsTableHeaderValues.Phone,
		key: 'contactPhoneNumber'
	},
	{
		header: ELeadsTableHeaderValues.Email,
		key: 'email'
	},
	{
		header: ELeadsTableHeaderValues.Company,
		key: 'company'
	},
	{
		header: '',
		key: 'Edit'
	}
];

const LeadsTable: React.FunctionComponent = () => {
	const FBContext = React.useContext(FirebaseContext);
	const { state, dispatch } = React.useContext(LeadsContext);
	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [showWarningModal, setShowWarningModal] = React.useState<boolean>(false);
	const [leadId, setLeadIdentifier] = React.useState<string>();

	React.useEffect((): void | any => {
		if (FBContext) {
			setLoading(true)

			const subscriber = FBContext
				.leads()
				.orderBy('received', 'desc')
				.onSnapshot((snapshot) => {
					const leads: ICustomer[] = [];
					if (snapshot.size > 0) {
						snapshot.forEach((doc: DocumentData) => {
							const tempObj = Object.assign({ id: doc.id }, doc.data()) as ICustomer;
							leads.push(tempObj);
						});
					}
					dispatch({
						type: ELeadsReducer.Leads,
						value: leads
					});
					setLoading(false)
				});

			return () => {
				subscriber();
			}
		}
	}, [dispatch, setLoading]);

	React.useEffect((): void | any => {
		const notes: INotes[] = [];
		if (FBContext && state.editLead.id && state.leadsView === ELeadsEditSteps.History) {
			const subscriber = FBContext
				.leadsHistory(state.editLead.id)
				.orderBy('date', 'desc')
				.onSnapshot((snapshot) => {
					if (snapshot.size > 0) {
						snapshot.forEach((doc: DocumentData) => {
							notes.push(doc.data() as INotes);
						});
					}

					dispatch({
						type: ELeadsReducer.LeadHistory,
						value: notes
					})
				});

			return () => {
				subscriber();
			}
		}
	}, [dispatch, state.editLead.id, state.leadsView]);

	const handleLeadDetails = React.useCallback((lead: ILead, leadsView: number) => {
		dispatch({
			type: ELeadsReducer.EditLead,
			value: { lead, leadsView }
		})

	}, [dispatch]);

	const deleteLead = React.useCallback(() => {
		if (FBContext) {
			setLoading(true);
			FBContext.leads().doc(leadId).delete()
				.then(() => {
					setLoading(false);
				})
		}
	}, [leadId, setLoading]);


	return (
		<React.Fragment>
			{state.leads.length === 0 && (
				<div>{'There are no leads'}</div>
			)}
			{isLoading && (
				<DataTableSkeleton />
			)}
			{(state.leads.length !== 0 && !isLoading) && (
				<div className={'sc--table-wrapper bx--data-table--zebra'}>
					<DataTable
						rows={state.leads}
						isSortable={true}
						headers={headers}
						render={({ rows, headers, getHeaderProps }) => (
							<TableContainer>
								<TableToolbar>
									<TableToolbarContent>
										<TableToolbarSearch
											className='sc--search-input'
											persistent={true}
											placeholder={'Filter by name'}
										/>
									</TableToolbarContent>
								</TableToolbar>
								<React.Fragment>
									<Table>
										<TableHead className={'sc--table-header'}>
											<TableRow>
												{headers.map((header: ITableHeaderItem) => (
													<TableHeader
														{...getHeaderProps({ header })}
														key={header.key}>
														{header.header}
													</TableHeader>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.map((row: IRow, index) => {
												return (<TableRow key={`${index}`} className={'sc--table-row'}>
													{row.cells.map((cell: ICell) => {
														if (cell.info.header === 'Edit') {
															return (
																<TableCell key={`${cell.id}-${index}`}>
																	<OverflowMenu flipped={true}>
																		{Object.values(ELeadsEditSteps).map((step, index: number): JSX.Element | undefined => {
																			if (typeof step !== 'number') {
																				return (
																					<OverflowMenuItem
																						key={`${step}-menu-item`}
																						itemText={step}
																						onClick={() => {
																							handleLeadDetails(state.leads[index], index)
																						}}
																						title={step}
																					/>
																				)
																			}
																			return
																		})}
																		<OverflowMenuItem hasDivider
																			isDelete
																			itemText={ELeadEditLabels.Delete}
																			onClick={() => {
																				setLeadIdentifier(state.leads[index].id);
																				setShowWarningModal(true);
																			}} />
																	</OverflowMenu>
																</TableCell>
															);
														} else if (cell.info.header === 'received') {
															if (cell.value) {
																return (
																	<TableCell key={cell.id}>
																		{ConvertNanoToDate(cell.value as ICellObject)}
																	</TableCell>
																);
															} else {
																return (
																	<TableCell key={cell.id}>
																		{'-'}
																	</TableCell>
																);
															}
														} else if (cell.info.header === 'firstName') {
															return (
																<TableCell key={cell.id}>
																	{`${cell.value} ${state.leads[index].lastName}`}
																</TableCell>
															)
														} else {
															return (
																<TableCell key={cell.id}>
																	{cell.value}
																</TableCell>
															);
														}
													})}
												</TableRow>
												)
											})}
										</TableBody>
									</Table>
									{/* <Pagination
						backwardText='Previous page'
						disabled={false}
						forwardText='Next page'
						isLastPage={false}
						itemRangeText={getItemRangeText}
						itemsPerPageText='Items per page:'
						onChange={this.handlePageChange}
						page={this.state.pagination.page}
						pageInputDisabled
						pageNumberText='Page Number'
						pageRangeText={getPageRangeText}
						pageSize={this.state.pagination.pageSize}
						pageSizes={PageSizes.pageSizes}
						totalItems={this.props.rows.length}
					/> */}
								</React.Fragment>
							</TableContainer>
						)}
					/>
				</div>
			)}
			{showWarningModal && (
				<ComposedModal
					{...{
						className: 'sc--composed-modal',
						open: showWarningModal,
						danger: true,
						onClose: () => {
							setShowWarningModal(false)
						}
					}}>
					<ModalHeader
						{...{
							buttonOnClick: () => {
								setShowWarningModal(false)
							},
							iconDescription: 'Close',
							title: 'Warning'
						}}
					/>
					<ModalBody>
						<div>
							<p className='sc--composed-modal--body'>
								Deleting a lead removes all contact history associated with the lead.  Click the Close button to cancel the operation.
							</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							{...{
								kind: 'secondary',
								onClick: () => {
									setShowWarningModal(false)
								}
							}}>
							{'Close'}
						</Button>
						<Button
							{...{
								kind: 'danger',
								onClick: () => {
									setShowWarningModal(false)
									deleteLead()
								}
							}}>
							{'Delete'}
						</Button>
					</ModalFooter>
				</ComposedModal>
			)}
		</React.Fragment>
	)
};

export {
	LeadsTable
}
